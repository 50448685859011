import React from 'react';

const Hero = () => {
  return (
    <div className='container-fluid p-0 m-0'>
      <div id="banner-image" className='p-0 m-0'>

      </div>
    </div>
  );
};

export default Hero;
