import React from 'react';

const Advocate = () => {
    return (

        <div className="container my-5">
            <h1 className='my-4'>Advocate At Council Meetings</h1>
            <p>Oakland City Council meetings are held on the first and third Tuesdays of each month and are open to the public. More information can be found here:
                <a href="https://oakland.legistar.com/Legislation.aspx" target='_blank' rel="noreferrer"> https://oakland.legistar.com/Legislation.aspx</a>
            </p>

            <ol>
                <li>
                    <strong>EMAIL PUBLIC COMMENTS</strong>
                    <ul>
                        <li>Email your comments to <a href="mailto:CityClerk@oaklandca.gov">CityClerk@oaklandca.gov</a>, and they will be delivered to council members before the meeting.</li>
                        <li>Comments must be submitted 24 hours prior to the meeting start time.</li>
                    </ul>
                </li>

                <li>
                    <strong>MAKE COMMENTS VIA ZOOM</strong>
                    <ul>
                        <li>Submit an Electronic Speaker Card (to speak via Zoom) by emailing <a href="mailto:CityClerk@oaklandca.gov">CityClerk@oaklandca.gov</a>.</li>
                        <li>Include your name, meeting date, and agenda item number in your email.</li>
                        <li>Your email subject should be: Public Comment for [meeting date], Item No [number(s)].</li>
                        <li>When the item is up, your Zoom name will be recognized. Unmute yourself to speak (press *6 if calling in by phone).</li>
                        <li>You'll be re-muted after your allotted time.</li>
                    </ul>
                </li>

                <li>
                    <strong>MAKE COMMENTS IN PERSON</strong>
                    <ul>
                        <li>In the Council Chambers, request a Speaker Card from the desk in the middle aisle.</li>
                        <li>Submit a Speaker Card for each item you want to comment on before the item is called.</li>
                        <li>When called, walk to the podium to speak for your allotted time.</li>
                    </ul>
                </li>
            </ol>

            <p>City Council Chambers are located on the third floor of City Hall, 1 Frank H. Ogawa Plaza, Oakland.</p>
        </div>

    );
};

export default Advocate;