import React from 'react';

const Home = () => {
  return (
    <div className='container p-5'>
      <div className='row gx-5 justify-content-center'>
        <div className='col-md-5 bg-white'>
          <h1>Welcome!</h1>
          <p>OPL Advocates is an informal coalition of advocacy groups, working together to maximize resources and amplify messages in support of Oakland Public Library.</p>
          <p>The coalition includes: the <a href="https://oaklandlibrary.org/lc/" target='_blank' rel="noreferrer">City of Oakland Library Commission</a>, the <a href="https://www.fopl.org/" target='_blank' rel="noreferrer">Friends of the Oakland Public Library</a>, <a href="https://www.fopl.org/partners" target='_blank' rel="noreferrer">Branch Friends groups</a> across the city, OPL&rsquo;s <a href="https://oaklandlibrary.org/teens/teen-leadership/" target='_blank' rel="noreferrer">Youth Leadership Council and Teen Advisory Boards</a>, the grassroots group Save Oakland Library, OPL staff, and patron advocates.</p>
          <p>Contact us at <a href="mailto:OPLAdvocates@gmail.com">OPLAdvocates@gmail.com</a>.</p>
        </div>
        <div className='col-md-5 mt-4 mt-md-0 px-0 px-md-3'>
          <img className="img-fluid" src="/images/kid.jpg" alt="" />
        </div>
        <div className='col-12 col-md-10 p-0 mt-4'>
          <a href="https://uniteagainstbookbans.org/" target='_blank' rel="noreferrer"><img className="img-fluid" src="/images/bannedbooks.jpg" alt="" /></a>
        </div>
      </div>
    </div>

  );
};

export default Home;