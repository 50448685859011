import React from 'react';
import { Link } from 'react-router-dom';

const handleNavLinkClick = () => {
  const content = document.querySelector('#main-content');
  const navbarToggler = document.querySelector('.navbar-toggler');
  const navbarCollapse = document.querySelector('.navbar-collapse');

  if (content) {
    // Fade out the content
    content.style.transition = 'opacity 0.5s';
    content.style.opacity = 0;

    // Wait for the fade-out before navigating
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      content.style.opacity = 1; // Fade back in
    }, 500); // Match the transition duration
  }

  // Close the navbar collapse menu if it's open
  if (navbarCollapse && navbarCollapse.classList.contains('show')) {
    navbarToggler.click();
  }
};


const Nav = () => {
  return (
    <nav className="navbar navbar-expand-lg border-bottom border-body">
      <div className="container-fluid d-flex justify-content-center ">
        <div className='d-flex flex-column justify-content-center align-items-center w-md-100'>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarColor01"
            aria-controls="navbarColor01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse justify-content-center me-lg-5 m-2 text-lg-center" id="navbarColor01">
          <ul className="navbar-nav mb-2 mb-lg-0 ">
            <li className="nav-item me-2 text-center">
              <Link className="nav-link active fs-4 fw-semibold" to="/" onClick={handleNavLinkClick}>Home</Link>
            </li>
            <li className="nav-item me-2 text-center">
              <Link className="nav-link fs-4 fw-semibold" to="/About" onClick={handleNavLinkClick}>About</Link>
            </li>
           <li className="nav-item me-2 text-center">
              <Link className="nav-link fs-4 fw-semibold" to="/Advocate" onClick={handleNavLinkClick}>Advocate</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
