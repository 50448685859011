import 'bootstrap/dist/js/bootstrap.bundle'; // Import Bootstrap JS
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './App.css'; // Custom CSS
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Nav from './components/NavBar';
import Footer from './components/Footers';
import Home from './pages/Home';
import About from './pages/About';
import Advocate from './pages/AdvocateAtMeetings';
import TopBar from './components/TopBar';
import Heros from './components/Heros';

function App() {
  return (
    <div id='main'>
      {/* Top bar at the top */}
      <TopBar />
      
      {/* Main navigation bar */}
      <Nav />
      
      {/* Hero section */}
      <Heros />
      
      {/* Main routes for content */}
      <div id="main-content" style={{ transition: 'opacity 0.5s ease-in-out', opacity: 1 }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Advocate" element={<Advocate />} />
        </Routes>
      </div>
      
      {/* Footer at the bottom */}
      <Footer />
    </div>
  );
}

export default App;
