import React from 'react'; 

const TopBanner = () => {
  return (
    <div id="#top" className='container-fluid py-3'>
      <div className='row justify-content-evenly'>
        <div className='col-12 d-flex justify-content-center'>
          <h1 className="text-center">Oakland Public Library Advocates</h1>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
