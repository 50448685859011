import React from 'react';
import { Link } from 'react-router-dom';

const handleNavLinkClick = () => {
  const content = document.querySelector('#main-content');
  const navbarToggler = document.querySelector('.navbar-toggler');
  const navbarCollapse = document.querySelector('.navbar-collapse');

  if (content) {
    // Fade out the content
    content.style.transition = 'opacity 0.5s';
    content.style.opacity = 0;

    // Wait for the fade-out before navigating
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      content.style.opacity = 1; // Fade back in
    }, 500); // Match the transition duration
  }

  // Close the navbar collapse menu if it's open
  if (navbarCollapse && navbarCollapse.classList.contains('show')) {
    navbarToggler.click();
  }
};

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white pt-3 pb-5">
      <div className="container text-center">
      <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item me-2">
              <Link className="nav-link active fs-4 fw-semibold" to="/" onClick={handleNavLinkClick}>Home</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link fs-4 fw-semibold" to="/About" onClick={handleNavLinkClick}>About</Link>
            </li>
           <li className="nav-item me-2">
              <Link className="nav-link fs-4 fw-semibold" to="/Advocate" onClick={handleNavLinkClick}>Advocate</Link>
            </li>
          </ul>
      </div>
    </footer>
  );
};

export default Footer;
